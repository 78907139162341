import React, { useContext, useEffect } from 'react';
import AppleLogin from 'react-apple-login';
import { Social, SocialLogo } from '../Login';
import { postSignInBySocial } from '../../../common/api/apiClient';
import { AppContext } from '../../../App';
import { doSyncRedirect, isDevEnv } from '../../../common/utils/utils';
import { useTrack } from '@hackler/react-sdk';

export const Apple: React.FC<{ onSocial: (social: Social) => void; onToken: () => void }> = ({ onSocial, onToken }) => {
  const { redirect } = useContext(AppContext);
  const track = useTrack();

  useEffect(() => {
    if (window.location.pathname.includes('apple')) {
      const hash = window.location.hash;
      console.log(hash);
      const id_token = hash.substring(hash.indexOf('id_token=') + 9);

      if (redirect && redirect?.includes('sync')) {
        return doSyncRedirect(redirect, id_token);
      }

      if (id_token) {
        postSignInBySocial(redirect!, 'apple', id_token).then(res => {
          if (res) {
            track({ key: 'click_login_apple' });
            onSocial({ provider: 'apple', access_token: id_token, ...res });
          } else {
            track({ key: 'click_signup_apple' });
            onToken();
          }
        });
      }
    }
  }, []);

  const storeState = () => {
    localStorage.setItem('apple-login-state', JSON.stringify({ redirect }));
  };

  return (
    <AppleLogin
      clientId={'im.candidate.auth'}
      redirectURI={`https://${isDevEnv() ? 'test-' : ''}auth.candidate.im/social-login/apple/callback`}
      responseType={'code id_token'}
      responseMode={'fragment'}
      render={p => (
        <SocialLogo
          src={'/images/login/apple.svg'}
          label={'애플'}
          onClick={() => {
            storeState();
            p.onClick();
          }}
          id={'apple-login'}
        />
      )}
    />
  );
};
